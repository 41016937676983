import React, { useRef, useMemo, useEffect, useState } from "react";

import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import ToiletIcon from "./ToiletIcon";
import { useToiGet } from "./ToiGetProvider";
import L from "leaflet";
import { Marker } from "react-leaflet";
import markerIcon from "../assets/marker-icon.png";

function ToiGetMap() {
  const {
    toiletList,
    currentPosition,
    setCurrentPosition,
    toiletPosition,
    setToiletPosition,
    //mapBounds,
    setMapBounds,
    mapCenterRef,
    mapZoomRef,
    insertMode,
    setInsertMode,
    setInsertError,
    updateMode,
    setUpdateMode,
    profile,
    posicionadoRef,
  } = useToiGet();

  //const INITIAL_POSITION = [-23.004678325889472, -43.31867551816686];

  const [map, setMap] = useState(null);
  

  //console.log(
  //  "ToiGetMap ",
  //  mapCenterRef.current,
  //  mapZoomRef.current,
  //  mapBounds
  //);

  useEffect(() => {
    //console.log("useEffect posicionadoRef.current ", posicionadoRef.current);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (map) {
          mapCenterRef.current = [
            position.coords.latitude,
            position.coords.longitude,
          ];
          if (!posicionadoRef.current) {
            //console.log("setView ", mapCenterRef.current);
            map.setView(mapCenterRef.current, mapZoomRef.current);
            posicionadoRef.current = true;
          }

          function getMapStatus() {
            let center = map.getCenter();
            //console.log("getMapStatus ", center);
            mapCenterRef.current = [center.lat, center.lng];
            setCurrentPosition(() => {
              localStorage.setItem(
                "currentPosition",
                JSON.stringify(mapCenterRef.current)
              );
              return mapCenterRef.current;
            });
            let bounds = map.getBounds();
            let mapBounds = {
              lat1: bounds._northEast.lat,
              long1: bounds._northEast.lng,
              lat2: bounds._southWest.lat,
              long2: bounds._southWest.lng,
            };
            //console.log(mapBounds, "ToiGetMap useEffect bounds");
            setMapBounds(() => mapBounds);
            let zoom = map.getZoom();
            //console.log(zoom);
            if (zoom === 10) {
              zoom = 14;
              map.setZoom(zoom);
            }
            //console.log(zoom);
            //setMapZoom(() => zoom);
            mapZoomRef.current = zoom;
          }
          getMapStatus();
          map.on("moveend", function (e) {
            getMapStatus();
          });
        }
      });
    }
  }, [map, setMapBounds, mapCenterRef, mapZoomRef, posicionadoRef, setCurrentPosition]);

  function isToilet(latlng) {
    // Quando clica próximo de um banheiro, considera alteração, ao invés de inclusão
    if (toiletList) {
      for (let i = 0; i < toiletList.length; i++) {
        let toiletLatLng = L.latLng(
          toiletList[i].latitude,
          toiletList[i].longitude
        );
        // se menos de 100 metros, ignora
        if (toiletLatLng.distanceTo(latlng) < 100) {
          return true;
        }
      }
    }
    return false;
  }

  function MapControl() {
    const map = useMapEvents({
      click(e) {
        // clicou em um banheiro? Se sim, ignora (será ativado o modo de alteração).
        if (!isToilet(e.latlng)) {
          if (updateMode) {
            setUpdateMode(() => false);
          }
          if (profile) {
            setToiletPosition(() => e.latlng);
            setInsertMode(() => true);
          } else {
            setInsertError(() => true);
          }
        }
      },
    });

    return null;
  }

  function DraggableMarker() {
    // Posição de referência para calcular as distâncias até os banheiros
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setCurrentPosition(() => {
              let pos1 = marker.getLatLng();
              let pos2 = [pos1.lat, pos1.lng];
              localStorage.setItem("currentPosition", JSON.stringify(pos2));
              return pos2;
            });
            setInsertMode(() => false);
            setUpdateMode(() => false);
          }
        },
      }),
      []
    );
    const streamingIcon = new L.icon({
      iconUrl: markerIcon,
      iconSize: [25, 41],
    });

    return (
      <Marker
        icon={streamingIcon}
        draggable={true}
        eventHandlers={eventHandlers}
        position={currentPosition ? currentPosition : mapCenterRef.current}
        ref={markerRef}
      ></Marker>
    );
  }

  function ToiletMarker() {
    let toiletIcon = L.icon({
      iconUrl: require("../assets/toilet-blue.png"),
      iconSize: [32, 32],
    });

    return toiletPosition === null ? null : (
      <Marker position={toiletPosition} icon={toiletIcon}></Marker>
    );
  }

  return (
    <MapContainer
      center={mapCenterRef.current}
      zoom={mapZoomRef.current}
      scrollWheelZoom={false}
      className="map"
      ref={setMap}
    >
      <MapControl />
      {toiletList
        ? toiletList.map((toilet, index) => (
            <ToiletIcon
              key={index}
              latitude={toilet.latitude}
              longitude={toilet.longitude}
              index={index}
              toiletEmail={toilet.email}
            />
          ))
        : null}
      <DraggableMarker />
      {insertMode && !updateMode && <ToiletMarker />}
      {updateMode && !insertMode && <ToiletMarker />}

      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
    </MapContainer>
  );
}

export default ToiGetMap;
