import React, { useState, createContext, useContext, useEffect, useRef } from "react";
import { googleLogout } from "@react-oauth/google";

import { atualizaDistancias } from "../util/utils";

const userAuthContext = createContext();

// Componente de contexto

export default function ToiGetProvider({ children }) {

  const [toiletList, setToiletList] = useState(null); // lista de banheiros (versão inicial, via JSON)
  const [distanceList, setDistanceList] = useState([]); // distâncias em relação ao ponto de referência
  const [currentPosition, setCurrentPosition] = useState(null); // ponto de referência para o cálculo das distâncias
  const [toiletPosition, setToiletPosition] = useState(null); // posição do banheiro que será incluído ou alterado
  const [mapBounds, setMapBounds] = useState(null);
  //const [mapCenter, setMapCenter] = useState([
  //  -23.004678325889472, -43.31867551816686,
  //]);
  //const [mapCenter, setMapCenter] = useState(null);
  //const [mapZoom, setMapZoom] = useState(10);
  //const mapBoundsRef = useRef(null);
  const mapCenterRef = useRef([-22, -43]);
  const mapZoomRef = useRef(10);

  const [insertMode, setInsertMode] = useState(false); // indica se há uma inclusão em andamento
  const [insertError, setInsertError] = useState(false); // indica se há um erro que impede a inclusão de um banheiro
  const [updateMode, setUpdateMode] = useState(false); // indica se há uma alteração em andamento
  const [updateError, setUpdateError] = useState(false); // indica se há um erro que impede a alteração de um banheiro
  const [queryMode, setQueryMode] = useState(false); // indica se há uma consulta em andamento
  const [updateAuthorizationError, setUpdateAuthorizationError] =
    useState(false);
  // indica se há um erro que impede a alteração de um banheiro por falta de autorização

  const [userGoogle, setUserGoogle] = useState(null);
  const [profile, setProfile] = useState(null);

  //const toiGetURLRef = useRef('stingray-app-ytrqr.ondigitalocean.app');
  //const toiGetURLRef = useRef('http://localhost:5000');
  const toiGetURLRef = useRef('https://api.toiget.com');
  //const toiGetURLRef = useRef('http://157.245.136.80:5001');

  const posicionadoRef = useRef(false);

  //console.log('ToiGetProvider mapCenter       ', mapCenterRef.current);
  //console.log('ToiGetProvider currentPosition ', currentPosition);

  // log out function to log the user out of google and set the profile array to null
  const logout = () => {
    googleLogout();
    setProfile(null);
    setUserGoogle(null);
  };

  useEffect(() => {
    if (currentPosition) {
      // se a posição de referência ou a lista de banheiros mudou, atualiza as distâncias
      localStorage.setItem("currentPosition", JSON.stringify(currentPosition));
      if (toiletList) {
        localStorage.setItem("toiletList", JSON.stringify(toiletList));
        setDistanceList(() => atualizaDistancias(toiletList, currentPosition));
      }
    }
  }, [currentPosition, toiletList]);

  useEffect(() => {

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setCurrentPosition(() => [
            position.coords.latitude,
            position.coords.longitude,
          ]);
        });
      }
  }, []);

  useEffect(() => {
    /* Inicialização da lista de banheiros */
    const getToilets = async () => {
      // Obtém a lista de banheiros
      let toilets = [];
      let url =
        toiGetURLRef.current + "/toilets?lat1=" +
        mapBounds.lat1 +
        "&long1=" +
        mapBounds.long1 +
        "&lat2=" +
        mapBounds.lat2 +
        "&long2=" +
        mapBounds.long2;
      //console.log(url);
      fetch(url, {
        method: "get",
        headers: {
          /*"Content-Type": "application/json", */
          "X-Api-Key": "vmi#%&*(4fnkadjfngladmvkaudfhg", 
        },
      })
        .then((response) => response.json())
        .then((data) => {
          data.toilets.forEach((toilet) => {
            var toiletX = toilet.toilet[0];
            var openingHours = toilet.toilet[1].openingHours;
            var horarios = [];
            for (var i = 0; i < openingHours.length; i++) {
              horarios.push({
                weekday: openingHours[i].weekday,
                openClosed: openingHours[i].openClosed,
                openingTime: openingHours[i].openingTime,
                closingTime: openingHours[i].closingTime,
              });
            }
            toilets.push({
              latitude: toiletX.latitude,
              longitude: toiletX.longitude,
              classification: toiletX.classification,
              description: toiletX.description,
              toiletType: toiletX.toiletType,
              email: toiletX.email,
              user: toiletX.user,
              openingHours: horarios,
            });
          });
          setToiletList(() => toilets);
          localStorage.setItem("toiletList", JSON.stringify(toilets));
          if (currentPosition) {
            setDistanceList(() => atualizaDistancias(toilets, currentPosition));
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      return;
    };
    const currentPosition = JSON.parse(localStorage.getItem("currentPosition")); // posição de referência
    if (currentPosition) {
      setCurrentPosition(() => currentPosition);
    } else {
      setCurrentPosition(() => [-23.004678325889472, -43.31867551816686]);
    }
    if (mapBounds) {
      let toiletList = [];
      getToilets();
      setToiletList(() => toiletList);
      localStorage.setItem("toiletList", JSON.stringify(toiletList));
      if (currentPosition) {
        setDistanceList(() => atualizaDistancias(toiletList, currentPosition));
      }
    }
  }, [mapBounds]);

  const value = {
    // lista de propriedades compartilhadas entre os componentes
    logout,
    toiletList,
    setToiletList,
    currentPosition,
    setCurrentPosition,
    toiletPosition,
    setToiletPosition,
    mapBounds,
    setMapBounds,
    mapCenterRef,
    mapZoomRef,
    distanceList,
    setDistanceList,
    insertMode,
    setInsertMode,
    insertError,
    setInsertError,
    updateMode,
    setUpdateMode,
    updateError,
    setUpdateError,
    updateAuthorizationError,
    setUpdateAuthorizationError,
    queryMode,
    setQueryMode,
    //user,
    //setUser,
    userGoogle,
    setUserGoogle,
    profile,
    setProfile,
    toiGetURLRef,
    posicionadoRef,
  };

  return (
    <userAuthContext.Provider value={value}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useToiGet() {
  return useContext(userAuthContext);
}
