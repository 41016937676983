import React, { useState, useEffect } from "react";

import { Container, Nav, Navbar, Button } from "react-bootstrap";
import ToiGetBrand from "./ToiGetBrand";
import { useToiGet } from "../components/ToiGetProvider";
//import { getUserName } from "../util/utils";
import { LinkContainer } from "react-router-bootstrap";

function ToiGetNavbar() {
  const [usuario, setUsuario] = useState();
  const { profile, logout } = useToiGet();

  useEffect(() => {
    if (profile) {
      //const emailLocal = JSON.parse(localStorage.getItem("email"));
      //setUsuario(getUserName(userList, emailLocal));
      setUsuario(() => profile.name);
    }
  }, [profile]);

  return (
    <Navbar expand="lg" bg="dark" variant="dark">
      <Container>
        <ToiGetBrand />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            {!profile && (
              <LinkContainer to="/login">
                <Nav.Link>Login</Nav.Link>
              </LinkContainer>
            )}
            <LinkContainer to="/help">
              <Nav.Link>Ajuda</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav className="me-auto">
            {profile && <Navbar.Text> Usuário: {usuario} </Navbar.Text>}
            {profile && (
              <div className="d-flex justify-content-end mb-3">
                <Container>
                  <Button variant="primary" onClick={logout}>
                    Logout
                  </Button>{" "}
                </Container>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default ToiGetNavbar;
