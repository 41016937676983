import React, { useState, useEffect } from "react";
import { useToiGet } from "../components/ToiGetProvider";
import { Button, Modal, Card } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import ToiGetBrand from "../components/ToiGetBrand";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

export default function LoginPage() {
  const { userGoogle, setUserGoogle, profile, setProfile } = useToiGet();

  //const [email, setEmail] = useState();
  //const [password, setPassword] = useState();

  //const [emailError, setEmailError] = useState(false);
  //const [passwordError, setPasswordError] = useState(false);
  const [backToHome, setBackToHome] = useState(false);
  const [show, setShow] = useState(true);

  //console.log('LoginPage');

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUserGoogle(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  // log out function to log the user out of google and set the profile array to null
  //const logout = () => {
  //  googleLogout();
  //  setProfile(null);
  //};

  //setShow(false);
  //setBackToHome(true);      

  useEffect(() => {
   if (userGoogle) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userGoogle.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${userGoogle.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          handleClose();
          //setShow(false);
          //return <Navigate to="/" />;
          //setShow(false);
          //setBackToHome(true);      
        })
        .catch((err) => console.log(err));
    }
  }, [userGoogle, setProfile]);

  const handleClose = () => {
    setShow(false);
    setBackToHome(true);
  };

  //function handleSubmit(e) {
  //  e.preventDefault();
  //  const userFound = userList.find((element) => element.email === email);
  //  if (userFound) {
  //    if (userFound.password === password) {
  //      onLogin();
  //      localStorage.setItem("user", JSON.stringify(userFound));
  //      localStorage.setItem("email", JSON.stringify(userFound.email));
  //      setUser(() => userFound);
  //      setBackToHome(true);
  //    } else {
  //      setPasswordError(true);
  //    }
  //  } else {
  //    setEmailError(true);
  //  }
  //}

  if (backToHome) {
    //console.log('LoginPage - backToHome');
    //return <Navigate to="/" replace />;
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose} data-bs-theme="dark">
        <Modal.Header closeButton>
          <ToiGetBrand />
          <Modal.Title style={{ margin: "0 auto" }}>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="shadow">
            <Card.Body> {/*}
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Informe o email"
                    value={email ? email : ""}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    value={password ? password : ""}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
  </Form> {*/}
              {profile ? (
                <div>
                  <h3>User Logged in</h3>
                  <p>Name: {profile.name}</p>
                  <p>Email Address: {profile.email}</p>
                  <br />
                  <br />
                  {/*}<Button variant="primary" onClick={logout}>Logout</Button> {*/}
                </div>
              ) : (
                <Button variant="primary" onClick={login}>
              Login via Google
            </Button>
              )}
            </Card.Body>
          </Card>{/*}
          {emailError && (
            <Alert
              variant="danger"
              onClose={() => setEmailError(false)}
              dismissible
            >
              <Alert.Heading>Login inválido</Alert.Heading>
              <p>Usuário não encontrado.</p>
            </Alert>
          )}
          {passwordError && (
            <Alert
              variant="danger"
              onClose={() => setPasswordError(false)}
              dismissible
            >
              <Alert.Heading>Senha inválida</Alert.Heading>
              <p>Senha incorreta.</p>
            </Alert>
          )} {*/}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button> {/*}
          <Button variant="primary" onClick={handleSubmit}>
            Login
        </Button> {*/}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
